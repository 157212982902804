import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";
import andriantamLogo from "../../assets/img/andriantam-light-small.png";
import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import "./portfolio.style.scss";
import { getPhotosByAlbum } from "../../controllers/getPhotos";
import useLoader from "../../hooks/useLoader";
import { PHOTO_CATEGORIES } from "../../constants/titles";
import { NAV } from "../../constants/paths";

const Photography = () => {
  const { setLoader, hideLoader } = useLoader();
  let { album } = useParams();
  const navigate = useNavigate();
  const [albumTitle, setAlbumTitle] = useState("");
  const [headerUrl, setHeaderUrl] = useState("/assets/img/footer-bg.jpg");
  const [images, setImages] = useState({
    count: 0,
    images: [
      {
        title: "",
        photoUrl: "",
      },
    ],
  });

  const getPhotos = (album: string) => {
    getPhotosByAlbum(album).then((data) => {
      setImages({ count: data.length, images: data });
      hideLoader();
    });
  };

  const { LOVE, AUTOMOTIVE, EVENTS, PRODUCTS, TRAVEL, FNB } = PHOTO_CATEGORIES;

  useEffect(() => {
    setLoader();
    switch (album) {
      case LOVE.id:
        setAlbumTitle(LOVE.title);
        getPhotos(LOVE.flickrId);
        setHeaderUrl(LOVE.headerUrl);
        break;
      case AUTOMOTIVE.id:
        setAlbumTitle(AUTOMOTIVE.title);
        getPhotos(AUTOMOTIVE.flickrId);
        setHeaderUrl(AUTOMOTIVE.headerUrl);
        break;
      case EVENTS.id:
        setAlbumTitle(EVENTS.title);
        getPhotos(EVENTS.flickrId);
        setHeaderUrl(EVENTS.headerUrl);
        break;
      case PRODUCTS.id:
        setAlbumTitle(PRODUCTS.title);
        getPhotos(PRODUCTS.flickrId);
        setHeaderUrl(PRODUCTS.headerUrl);
        break;
      case TRAVEL.id:
        setAlbumTitle(TRAVEL.title);
        getPhotos(TRAVEL.flickrId);
        setHeaderUrl(TRAVEL.headerUrl);
        break;
      case FNB.id:
        setAlbumTitle(FNB.title);
        getPhotos(FNB.flickrId);
        setHeaderUrl(FNB.headerUrl);
        break;
      default:
        navigate(NAV.PORTFOLIO);
        break;
    }
    document.title = `andriantam | Photography - ${albumTitle}`;
  }, [headerUrl]);

  return (
    <>
      <Header isPortfolio={true} />
      <main id="main">
        <section
          id="portfolio-hero"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("${headerUrl}")`,
            backgroundPosition: `center 30%`,
          }}
        >
          <div className="secondary-hero-container">
            <LazyLoadImage
              src={andriantamLogo}
              className="andriantam-logo"
              alt=""
              effect="blur"
            />
            <h2>{albumTitle}</h2>
          </div>
        </section>

        <Breadcrumbs
          secondLevelTitle="Portfolio"
          secondLevelUrl={NAV.PORTFOLIO}
          thirdLevelTitle={albumTitle}
        />
        <section>
          <div className="container position-relative">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                {images.images.length > 0 ? (
                  images.images.map(({ photoUrl }, key) => {
                    return (
                      <div className="masonry-photo-frame" key={key}>
                        <LazyLoadImage src={photoUrl} alt="" effect="blur" />
                      </div>
                    );
                  })
                ) : (
                  <h4>No Photos available</h4>
                )}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </section>
      </main>
    </>
  );
};

export default Photography;
