import { useMediaQuery } from "react-responsive";
import { NAV } from "../constants/paths";

import lom from "../assets/img/brands/lambo-owners-malaysia.png";
import recstorm from "../assets/img/brands/recstorm.png";
import momenz from "../assets/img/brands/momenz.png";
import troopers from "../assets/img/brands/troopers.png";
import scientex from "../assets/img/brands/scientex.png";
import dsHome from "../assets/img/brands/ds-home.png";
import oversteer from "../assets/img/brands/oversteer-sq.png";
import ferrari from "../assets/img/brands/ferrari-owners.png";
import robbReport from "../assets/img/brands/robb-report.png";
import chery from "../assets/img/brands/chery.png";
import controls from "../assets/img/brands/controls.png";
import gac from "../assets/img/brands/gac.png";
import giti from "../assets/img/brands/giti.png";
import jaecoo from "../assets/img/brands/jaecoo.png";
import kazuto from "../assets/img/brands/kazuto.png";
import autoshow from "../assets/img/brands/mas.png";
import mg from "../assets/img/brands/mg.png";
import fanatec from "../assets/img/brands/fanatec.png";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Services = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <>
      <section id="services" className="services">
        <div className="container">
          <div className="section-title">
            <span>My Services</span>
            <h2>My Services</h2>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="icon">
                  <i className="bx bx-camera"></i>
                </div>
                <h4 className="title">Digital Photography</h4>
                <p className="description">
                  With more than 15 years of experience shooting as a passionate
                  hobbyist, andriantam continues to take photographs in his
                  favorite style of Nat Geo and cinematic film.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="icon">
                  <i className="bx bx-camera"></i>
                </div>
                <h4 className="title">Film Photography</h4>
                <p className="description">
                  Andrian, being technical, has been curious about the roots and
                  fundamentals of photography that he has dived into film
                  photography in both medium and 35mm format. He has shot
                  weddings in film before.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={`${NAV.PORTFOLIO}/videography`}>
                <div className="icon">
                  <i className="bx bx-camera-movie"></i>
                </div>
                <h4 className="title">Videography</h4>
                <p className="description">
                  Focused on capturing moments and Reels, Andrian loves making
                  videos and has even got runner up award in competitions under
                  the name of Tam's Up Productions.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="icon">
                  <i className="bx bx-joystick"></i>
                </div>
                <h4 className="title">Drone Piloting</h4>
                <p className="description">
                  To get a different angle, Andrian has been flying drones from
                  the DJI Spark to DJI Mini 3 Pro for capturing cinematic
                  B-rolls.
                </p>
              </a>
            </div>

            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.RESUME}>
                <div className="icon">
                  <i className="bx bx-world"></i>
                </div>
                <h4 className="title">Web Development</h4>
                <p className="description">
                  Andrian has been building software for various industries and
                  clients across multiple countries for almost 10 years.
                </p>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-5">
              <a className="icon-box" href={NAV.PORTFOLIO}>
                <div className="icon">
                  <i className="bx bx-car"></i>
                </div>
                <h4 className="title">Automotive Parts Dealer</h4>
                <p className="description">
                  Andrian is an online dealer for Nankang Motorsports Malaysia
                  tyres and Takumi Motor Oil Malaysia.
                </p>
              </a>
            </div>
          </div>

          <div className="row">
            <div className="btn-wrap">
              <a href={NAV.PORTFOLIO} className="btn-action">
                Go To My Portfolio
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="section-title">
            {/* <span>Clients / Brands / Events Served</span> */}
            <h2>Clients / Brands / Events Served</h2>
          </div>

          <div className="row">
            <div className="brand-img">
              <a
                href="https://www.instagram.com/recstorm_pictures/"
                target="blank"
              >
                <LazyLoadImage
                  src={recstorm}
                  className="recstorm-pictures"
                  alt="recstorm pictures"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://momenz.my/" target="blank">
                <LazyLoadImage
                  src={momenz}
                  className="momenz"
                  alt="momenz creation"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.facebook.com/OversteerMovie/" target="blank">
                <LazyLoadImage
                  src={oversteer}
                  className="oversteer"
                  alt="Oversteer Movie"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://autoshow.my/" target="blank">
                <LazyLoadImage
                  src={autoshow}
                  className="malaysia-autoshow"
                  alt="Malaysia Autoshow"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.troopers.com.my/home" target="blank">
                <LazyLoadImage
                  src={troopers}
                  className="troopers"
                  alt="troopers my part time manpower made easy"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://scientex.com.my/" target="blank">
                <LazyLoadImage
                  src={scientex}
                  className="scientex"
                  alt="scientex healthy happy friendly"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.gt-world-challenge.com/" target="blank">
                <LazyLoadImage
                  src={fanatec}
                  className="fanatec-gt"
                  alt="Fanatec GT World Challenge Sepang Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://robbreport.com.my/" target="blank">
                <LazyLoadImage
                  src={robbReport}
                  className="robb-report"
                  alt="Robb Report"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://focm.com.my/" target="blank">
                <LazyLoadImage
                  src={ferrari}
                  className="ferrari"
                  alt="Ferrari Owners Club Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://dshome2u.com.my/" target="blank">
                <LazyLoadImage
                  src={dsHome}
                  className="ds-home"
                  alt="ds home logo"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.chery.my/" target="blank">
                <LazyLoadImage
                  src={chery}
                  className="chery"
                  alt="Chery Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.controls.com.my/" target="blank">
                <LazyLoadImage
                  src={controls}
                  className="controls-switchgear"
                  alt="Controls Switchgear"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://gacmotor.com.my/" target="blank">
                <LazyLoadImage
                  src={gac}
                  className="gac"
                  alt="GAC Motors"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.giti.com.sg/" target="blank">
                <LazyLoadImage
                  src={giti}
                  className="giti"
                  alt="Giti Tyres Singapore"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.mgmalaysia.com/" target="blank">
                <LazyLoadImage
                  src={mg}
                  className="mg"
                  alt="MG Motor Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.jaecoo.com.my/" target="blank">
                <LazyLoadImage
                  src={jaecoo}
                  className="jaecoo"
                  alt="Jaecoo Malaysia"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a href="https://www.youtube.com/c/KazutoGarage" target="blank">
                <LazyLoadImage
                  src={kazuto}
                  className="kazuto"
                  alt="Kazuto Garage"
                  effect="blur"
                />
              </a>
            </div>

            <div className="brand-img">
              <a
                href="https://www.facebook.com/LamborghiniOwnersMalaysia"
                target="blank"
              >
                <LazyLoadImage
                  src={lom}
                  className="lom"
                  alt="lamborghini owners malaysia"
                  effect="blur"
                />
              </a>
            </div>
          </div>

          <div className="row">
            <div className="btn-wrap">
              <a href={NAV.PORTFOLIO} className="btn-action">
                Go To My Portfolio
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
