import axios from "axios";

export const getPhotosByAlbum = (album: string): Promise<any> => {
  return axios
    .get(
      `https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=46c8e747feb3b78a81e396ade04b51e3&photoset_id=${album}&user_id=39413324%40N06&format=json&nojsoncallback=1`
    )
    .then(({ data: { photoset } }) => {
      const tempPhotoArray: any = [];
      photoset.photo.forEach(({ server, id, secret }: any) => {
        tempPhotoArray.push({
          title: "",
          photoUrl: `https://farm66.staticflickr.com/${server}/${id}_${secret}_c.jpg`,
        });
      });
      return tempPhotoArray;
    })
    .catch((err) => err);
};
